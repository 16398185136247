import React from 'react';
import ReactDOM from 'react-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LandingPage from './LandingPage';
import './globalStyles.css'
import './i18n';

ReactDOM.render(
    <React.StrictMode>
        <LandingPage />
    </React.StrictMode>,
    document.getElementById('root')
);
