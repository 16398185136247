/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FaArrowRight } from 'react-icons/fa'; // Optional: Icon for button

const buttonStyles = (variant, size, disabled) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: ${size === 'large' ? '14px 28px' : '10px 20px'};
  font-size: ${size === 'large' ? '18px' : '16px'};
  font-weight: var(--font-weight-semi-bold);
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  transition: background-color 0.3s, transform 0.1s ease-in-out;
  background-color: ${getBackgroundColor(variant, disabled)};
  color: ${variant === 'light' ? 'var(--color-dark-neutral-03)' : '#fff'};
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 35px;

  &:hover {
    background-color: ${getHoverColor(variant)};
    transform: ${!disabled ? 'scale(1.05)' : 'none'};
  }

  &:active {
    transform: scale(0.98);
  }
`;

/* Helper functions to determine button colors */
const getBackgroundColor = (variant, disabled) => {
    if (disabled) return 'var(--color-light-neutral-03)';

    switch (variant) {
        case 'dark':
            return 'var(--color-dark-neutral-01)';
        case 'medium':
            return 'var(--color-brand-medium)';
        case 'light':
            return 'var(--color-brand-pale-01)';
        case 'pale':
            return 'var(--color-brand-pale-01)';
        default:
            return 'var(--color-brand-medium)';
    }
};

const getHoverColor = (variant) => {
    switch (variant) {
        case 'dark':
            return 'var(--color-dark-neutral-02)';
        case 'medium':
            return 'var(--color-brand-light)';
        case 'light':
            return 'var(--color-brand-pale-02)';
        default:
            return 'var(--color-brand-medium)';
    }
};

const Button = ({children, variant = 'medium', size = 'medium', disabled = false, href}) => {
    const buttonContent = (
        <>
            {children}
            <FaArrowRight/>
        </>
    );

    if (href) {
        return (
            <a
                href={href}
                css={buttonStyles(variant, size, disabled)}
                aria-disabled={disabled}
                onClick={(e) => disabled && e.preventDefault()}
            >
                {buttonContent}
            </a>
        );
    }

    return (
        <button css={buttonStyles(variant, size, disabled)} disabled={disabled}>
            {buttonContent}
        </button>
    );
};

export default Button;
