import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Function to dynamically load all translation files
const importAllTranslations = () => {
    const resources = {};
    const context = require.context('./locales', true, /\.json$/); // Load all .json files in locales folder

    context.keys().forEach((filePath) => {
        const matched = filePath.match(/\.\/([a-z]{2})\/(.+)\.json$/); // Extract language and namespace
        if (matched) {
            const [, lang, namespace] = matched;
            if (!resources[lang]) resources[lang] = {}; // Initialize language if not exists
            resources[lang][namespace] = context(filePath); // Add namespace to the language
        }
    });

    return resources;
};

const resources = importAllTranslations();

i18n
    .use(LanguageDetector) // Detect user language
    .use(initReactI18next) // Passes i18n down to React
    .init({
        resources, // Use dynamically loaded resources
        lng: 'en', // Default language
        fallbackLng: 'en', // Fallback language
        ns: Object.keys(resources.en || {}), // Automatically use all namespaces from English
        defaultNS: 'common', // Default namespace
        interpolation: {
            escapeValue: false, // React already escapes
        },
    });

export default i18n;
