/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import AccordionItem from './atoms/AccordionItem';
import { useTranslation } from 'react-i18next';

const sectionStyles = css`
  background-color: #f6f7f9;
  padding: 80px 20px;
  text-align: center;
`;

const titleStyles = css`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #46ac35;
`;

const subtitleStyles = css`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 40px;
  color: #0d0e0d;
`;

const faqContainer = css`
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const buttonContainer = css`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const buttonStyles = css`
  background-color: var(--color-brand-medium);
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--color-brand-dark);
  }
`;

const FAQSection = () => {
    const { t } = useTranslation('faq'); // Specify the namespace for translations

    return (
        <section css={sectionStyles}>
            <h1 css={titleStyles}>{t('title')}</h1>
            <h2 css={subtitleStyles}>{t('subtitle')}</h2>

            <div css={faqContainer}>
                {t('questions', { returnObjects: true }).map((item, index) => (
                    <AccordionItem
                        key={index}
                        question={item.question}
                        answer={item.answer}
                    />
                ))}
            </div>

            <div css={buttonContainer}>
                <button css={buttonStyles}>{t('cta')}</button>
            </div>
        </section>
    );
};

export default FAQSection;
