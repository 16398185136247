/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import HeroImage from '../assets/hero-image.svg';
import Button from "./atoms/Button"; // Adjust path if needed

const heroStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px;
    background: radial-gradient(circle at top, var(--color-brand-medium) 50%, var(--color-dark-neutral-03) 100%);
    color: white;
    min-height: 640px;

    @media (max-width: 768px) {
        background: radial-gradient(circle at top, var(--color-brand-medium) 55%, var(--color-dark-neutral-03) 80%);
        flex-direction: column;
        text-align: center;
    }
`;

const heroText = css`
    max-width: 600px;

    h1 {
        font-size: 48px;
        margin-bottom: 20px;
        color: white;
    }

    p {
        font-size: 18px;
        color: var(--color-light-neutral-01);
    }
`;

const heroImageStyles = css`
    max-width: 400px;
    width: 100%;
    margin-left: 40px;

    @media (max-width: 768px) {
        margin-left: 0;
        margin-top: 20px;
    }
`;

const HeroSection = () => {
    const { t } = useTranslation('heroSection');

    return (
        <section css={heroStyles}>
            <div css={heroText}>
                <h1>{t('hero.title')}</h1>
                <p>{t('hero.subtitle')}</p>
                <Button variant="light" size="small">{t('hero.cta')}</Button>
            </div>
            <img src={HeroImage} alt="Hero" css={heroImageStyles} />
        </section>
    );
};

export default HeroSection;
