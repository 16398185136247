/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FaLinkedin, FaInstagram, FaEnvelope } from 'react-icons/fa'; // Icons for social media
import { useTranslation } from 'react-i18next';
import PromoLogo from "../assets/promoLogo.svg";

const footerStyles = css`
  background-color: var(--color-brand-medium);
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
    text-align: left;
    gap: 0;
  }
`;

const logoStyles = css`
  font-size: 64px; /* Placeholder for logo styling */
  font-weight: bold;
  color: var(--color-dark-neutral-03);
`;

const linkContainerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 40px;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    color: var(--color-dark-neutral-03);
  }
`;

const socialIconsContainer = css`
  display: flex;
  gap: 20px;

  svg {
    font-size: 24px;
    cursor: pointer;
    color: var(--color-dark-neutral-03);
    transition: color 0.3s;

    &:hover {
      color: var(--color-light-neutral-01);
    }
  }
`;

const copyrightStyles = css`
    background-color: var(--color-dark-neutral-03);
    padding: 10px 20px;
    text-align: center;
    color: var(--color-light-neutral-01);
    font-size: 14px;
`;

const Footer = () => {
    const { t } = useTranslation('footer'); // Specify the namespace for translations

    return (
        <>
            <footer css={footerStyles}>
                {/*<div css={logoStyles}>🙂</div>*/}
                <img
                src={PromoLogo}
                alt={t('PromoLogoAlt')}
                css={logoStyles}
                />
                <div css={linkContainerStyles}>
                    <a href="#how-it-works">{t('links.howItWorks')}</a>
                    <a href="#pricing">{t('links.pricing')}</a>
                    <a href="#faq">{t('links.faq')}</a>
                    <a href="#terms">{t('links.terms')}</a>
                    <a href="#privacy">{t('links.privacy')}</a>
                    <a href="#press-kit">{t('links.pressKit')}</a>
                </div>

                <div css={socialIconsContainer}>
                    <FaLinkedin />
                    <FaInstagram />
                    <FaEnvelope />
                </div>
            </footer>

            <div css={copyrightStyles}>
                <p>{t('copyright')}</p>
                <p>{t('designerCredit')}</p>
            </div>
        </>
    );
};

export default Footer;
