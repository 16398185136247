/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import SectionContainer from './SectionContainer';
import ParallaxImage from '../assets/parallax.svg'; // Adjust path if needed
import { useTranslation } from 'react-i18next';

const contentStyles = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const textContent = css`
    color: white;
    max-width: 600px;

    h2 {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    p {
        font-size: 18px;
        margin-bottom: 20px;
    }
`;

const imageStyles = css`
    max-width: 400px;
    width: 100%;
`;

const parallaxBg = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${ParallaxImage});
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: -1;
`;

const ParallaxSection = () => {
    const { t } = useTranslation('parallax'); // Specify the namespace for translations

    return (
        <SectionContainer bgColor="var(--color-brand-medium)">
            <div css={parallaxBg} />
            <div css={contentStyles}>
                <img
                    src={ParallaxImage}
                    alt={t('parallaxImageAlt')} // Alt text translation key
                    css={imageStyles}
                />
                <div css={textContent}>
                    <h2>{t('analytics.title')}</h2>
                    <p>{t('analytics.description')}</p>

                    <h2>{t('security.title')}</h2>
                    <p>{t('security.description')}</p>

                    <h2>{t('compliance.title')}</h2>
                    <p>{t('compliance.description')}</p>
                </div>
            </div>
        </SectionContainer>
    );
};

export default ParallaxSection;
