/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import HowItWorksCard from './HowItWorksCard';
import HowItWorksBg from '../assets/howItWorksBg.gif'; // GIF Background
import GiftSVG from '../assets/giftSvg.svg';

const sectionPlaceholderStyles = css`
    position: relative;
    padding: 40px;
    color: #0D0E0D;
    text-align: center;
    overflow: hidden;
    background-color: #010000;

    @media (max-width: 768px) {
        padding: 60px 20px;
    }
`;

const titleStyles = css`
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    color: var(--color-light-neutral-01);
    @media (max-width: 768px) {
        font-size: 28px;
    }
`;

const descriptionStyles = css`
    font-size: 18px;
    margin-bottom: 40px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    color: var(--color-light-neutral-01);
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const carouselStyles = css`
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 40px;

    @media (max-width: 768px) {
        width: 100%; /* Full width on mobile */
    }
`;

const sliderStyles = css`
    .slick-center {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
`;

const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                centerMode: false,
            }
        }
    ]
};

const HowItWorks = () => {
    const { t } = useTranslation('howItWorks'); // Specify the namespace
    const [isInView, setIsInView] = useState(false); // Track when section is visible
    const sectionRef = useRef(null);

    useEffect(() => {
        const currentSection = sectionRef.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsInView(true); // Load GIF when in view
                }
            },
            { threshold: 0.1 }
        );

        if (currentSection) {
            observer.observe(currentSection);
        }

        return () => {
            if (currentSection) {
                observer.unobserve(currentSection);
            }
        };
    }, []);

    return (
        <section
            id="HowItWorks"
            ref={sectionRef}
            css={css`
                ${sectionPlaceholderStyles};
            `}
        >
            <div css={css`
                background-image: ${isInView ? `url(${HowItWorksBg})` : 'none'};
                background-size: auto;
                background-position: center;
                background-repeat: no-repeat;
                transition: background-image 0.5s ease-in-out;
            `}>
                <h2 css={titleStyles}>{t('title')}</h2>
                <p css={descriptionStyles}>{t('description')}</p>

                <div css={carouselStyles}>
                    <Slider {...carouselSettings} css={sliderStyles}>
                        <HowItWorksCard
                            image={GiftSVG}
                            title={t('steps.step1.title')}
                            description={t('steps.step1.description')}
                        />
                        <HowItWorksCard
                            image={GiftSVG}
                            title={t('steps.step2.title')}
                            description={t('steps.step2.description')}
                        />
                        <HowItWorksCard
                            image={GiftSVG}
                            title={t('steps.step3.title')}
                            description={t('steps.step3.description')}
                        />
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
