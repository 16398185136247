/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaLinkedin, FaInstagram, FaEnvelope } from 'react-icons/fa';
import { FaChevronDown } from 'react-icons/fa';
import Button from './atoms/Button';

const headerStyles = (isVisible) => css`
    padding: 20px 40px;
    background-color: var(--color-brand-medium);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: ${isVisible ? '0' : '-80px'};
    left: 0;
    right: 0;
    transition: top 0.3s ease-in-out;
    z-index: 1000;

    @media (max-width: 768px) {
        padding: 10px 20px;
    }
`;

const logoStyles = css`
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #fff;

    img {
        margin-right: 10px;
        height: 30px;
    }

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const navStyles = css`
    display: flex;
    align-items: center;
    gap: 20px;

    a {
        text-decoration: none;
        font-weight: var(--font-weight-medium);
        color: #fff;

        &:hover {
            color: var(--color-light-neutral-01);
        }
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

const mobileMenuStyles = (isOpen) => css`
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 65%;
    height: 100vh;
    background-color: var(--color-light-neutral-01);
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 2000;

    .menu-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h2 {
            font-size: 18px;
            font-weight: var(--font-weight-semi-bold);
        }

        button {
            background: none;
            border: none;
            font-size: 20px;
            cursor: pointer;
        }
    }

    a {
        display: block;
        margin-bottom: 10px;
        font-size: 16px;
        text-decoration: none;
        font-weight: var(--font-weight-medium);
        color: #333;

        &:hover {
            color: var(--color-brand-medium);
        }
    }

    .connect-section, .country-section, .more-section {
        margin-top: 20px;

        h3 {
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: var(--font-weight-semi-bold);
            color: var(--color-dark-neutral-01);
        }
    }

    button {
        width: 100%;
        margin-top: 10px;
    }
`;

const mobileMenuIcon = css`
    display: none;

    @media (max-width: 768px) {
        display: block;
        font-size: 28px;
        cursor: pointer;
        color: #fff;
    }
`;

const languageDropdownStyles = css`
    position: relative;

    .dropdown {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        font-size: 16px;
        color: #fff;

        &:hover {
            color: var(--color-light-neutral-01);
        }
    }

    .dropdown-menu {
        position: absolute;
        top: 30px;
        right: 0;
        background-color: var(--color-light-neutral-01);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
        display: ${({ isDropdownOpen }) => (isDropdownOpen ? 'block' : 'none')};
        z-index: 2000;

        button {
            display: block;
            width: 100%;
            padding: 10px 20px;
            background: none;
            border: none;
            text-align: left;
            cursor: pointer;
            color: var(--color-dark-neutral-01);
            font-size: 16px;

            &:hover {
                background-color: var(--color-light-neutral-03);
            }
        }
    }
`;

const LINKS = [
    { href: "#features", text: "Features" },
    { href: "#pricing", text: "Pricing" },
    { href: "#HowItWorks", text: "How It Works" },
    { href: "#contact", text: "Contact" }
];

const Header = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Track mobile menu state
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track language dropdown state
    const { t, i18n } = useTranslation();
    const menuRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY <= 20) {
                setIsVisible(true);
            } else if (currentScrollY > lastScrollY) {
                setIsVisible(false);
            } else {
                setIsVisible(true);
            }

            setLastScrollY(currentScrollY);
        };

        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [lastScrollY]);

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setIsDropdownOpen(false);
    };

    const renderNavLinks = () => (
        LINKS.map(link => (
            <a key={link.href} href={link.href}>
                {t(link.text)}
            </a>
        ))
    );

    return (
        <header css={headerStyles(isVisible)}>
            <div css={logoStyles}>
                <img src="/promo.png" alt="PromoPass Logo" />
                <span>PromoPass</span>
            </div>

            {/* Desktop Navigation */}
            <nav css={navStyles}>
                {renderNavLinks()}
                <Button variant="medium" size="small">{t('Log in')}</Button>
                <Button variant="light" size="small">{t('Book a call')}</Button>
                <div css={languageDropdownStyles} onClick={() => setIsDropdownOpen((prev) => !prev)}>
                    <div className="dropdown">
                        {i18n.language.toUpperCase()} <FaChevronDown />
                    </div>
                    <div className="dropdown-menu" style={{ display: isDropdownOpen ? 'block' : 'none' }}>
                        <button onClick={() => changeLanguage('en')}>EN</button>
                        <button onClick={() => changeLanguage('es')}>MX</button>
                    </div>
                </div>
            </nav>

            {/* Mobile Menu */}
            <div
                css={mobileMenuIcon}
                onClick={() => setIsMenuOpen((prev) => !prev)}
            >
                ☰
            </div>

            <div css={mobileMenuStyles(isMenuOpen)} ref={menuRef}>
                <div className="menu-header">
                    <h2>{t('Menu')}</h2>
                    <button onClick={() => setIsMenuOpen(false)}>×</button>
                </div>
                {renderNavLinks()}
                <Button variant="medium" size="small">{t('Log in')}</Button>
                <Button variant="light" size="small">{t('Book a call')}</Button>

                <div className="connect-section">
                    <h3>{t('Connect with us')}</h3>
                    <FaLinkedin /> <FaInstagram /> <FaEnvelope />
                </div>

                <div className="country-section">
                    <h3>{t('Country')}</h3>
                    <select onChange={(e) => changeLanguage(e.target.value)} defaultValue={i18n.language}>
                        <option value="en">English</option>
                        <option value="es">Spanish</option>
                    </select>
                    <p>{t('Beta availability')}</p>
                </div>
            </div>
        </header>
    );
};

export default Header;
