/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

const sectionStyles = css`
  background-color: #CFFFD0; /* Light green background from your design */
  padding: 80px 20px;
  text-align: center;
`;

const titleStyles = css`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #0D0E0D;
  text-transform: uppercase;
`;

const subtitleStyles = css`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const cardsContainer = css`
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap; /* Ensure responsiveness */
  max-width: 1200px;
  margin: 0 auto;
`;

const cardStyles = css`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 320px;
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const darkCardStyles = css`
  background-color: #0D0E0D; /* Dark card for advanced plan */
  color: white;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 30px;
  width: 320px;
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const planTitleStyles = css`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  background-color: #E8F8E9;
  color: #46AC35;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 12px;
`;

const priceStyles = css`
  font-size: 48px;
  color: #46AC35;
  margin: 10px 0;
`;

const featuresList = css`
  margin: 20px 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  li::before {
    content: '✔️';
    margin-right: 10px;
    color: #46AC35;
  }
`;

const buttonStyles = css`
  width: 100%;
  padding: 12px 0;
  background-color: #46AC35;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #39842A;
  }
`;

const PricingSection = () => {
    const { t } = useTranslation('pricing'); // Specify the namespace for translations

    return (
        <section css={sectionStyles} id="pricing">
            <h1 css={titleStyles}>{t('title')}</h1>
            <h2 css={subtitleStyles}>{t('subtitle')}</h2>

            <div css={cardsContainer}>
                {/* Small Business Card */}
                <div css={cardStyles}>
                    <span css={planTitleStyles}>{t('smallBusiness.planTitle')}</span>
                    <h3>{t('smallBusiness.packageName')}</h3>
                    <div css={priceStyles}>
                        {t('smallBusiness.price.amount')}{' '}
                        <span style={{ fontSize: '18px' }}>{t('smallBusiness.price.unit')}</span>
                    </div>
                    <ul css={featuresList}>
                        {t('smallBusiness.features', { returnObjects: true }).map((feature, index) => (
                            <li key={index}>{feature}</li>
                        ))}
                    </ul>
                    <button css={buttonStyles}>{t('smallBusiness.cta')}</button>
                </div>

                {/* Expansion Card */}
                <div css={darkCardStyles}>
                    <span css={planTitleStyles}>{t('expansion.planTitle')}</span>
                    <h3>{t('expansion.packageName')}</h3>
                    <h3 style={{ color: '#CFFFD0' }}>{t('expansion.getInTouch')}</h3>
                    <ul css={featuresList}>
                        {t('expansion.features', { returnObjects: true }).map((feature, index) => (
                            <li key={index}>{feature}</li>
                        ))}
                    </ul>
                    <button css={buttonStyles}>{t('expansion.cta')}</button>
                </div>
            </div>

            <p style={{ marginTop: '20px', color: '#0D0E0D' }}>
                {t('footerNote')}
            </p>
        </section>
    );
};

export default PricingSection;
