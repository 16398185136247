/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Footer from './components/Footer';
import HowItWorks from "./components/HowItWorks";
import ParallaxSection from "./components/ImgParallax";
import PricingSection from "./components/PricingSection";
import FAQSection from "./components/FAQSection";
import CTASection from "./components/CTASection";

const globalStyles = css`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box; /* Ensure consistent sizing */
    }

    body {
        background-color: #F7F8F7;
        overflow-x: hidden; /* Prevent horizontal overflow */
        font-family: 'Arial', sans-serif;
    }
`;

const pageStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-light-neutral-02); /* Light background */
    min-height: 100vh;
    width: 100%; /* Ensure full width */
`;

const containerStyles = css`
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0 10px; /* Adjust padding for mobile */
  }
`;

const LandingPage = () => (
    <>
        <div styles={globalStyles} />
        <div css={pageStyles}>
            <div css={containerStyles}>
                <Header />
                <HeroSection />
                <HowItWorks />
                <ParallaxSection />
                <PricingSection />
                <FAQSection />
                <CTASection />
                <Footer />
            </div>
        </div>
    </>
);

export default LandingPage;
