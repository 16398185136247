/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'; // Icons for expand/collapse

const itemStyles = css`
  border-bottom: 1px solid #e0e0e0;
  padding: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;

  &:hover {
    background-color: #f9f9f9;
  }
`;

const answerStyles = css`
  padding: 0 20px 20px;
  color: #555;
  font-size: 16px;
`;

const AccordionItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <div css={itemStyles} onClick={() => setIsOpen(!isOpen)}>
                <span>{question}</span>
                {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
            {isOpen && <div css={answerStyles}>{answer}</div>}
        </div>
    );
};

export default AccordionItem;
