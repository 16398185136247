/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const cardStyles = css`
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    margin: auto;
    padding: 40px;
    gap: 8px;
    width: 600px;
    min-height: 287px;

    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #202220;
    backdrop-filter: blur(5px);
    border-radius: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;
    
    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
        padding: 20px;
        width: 300px;
    }
`;

const imageStyles = css`
  max-width: 150px;
  width: 100%;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
    max-width: 120px;
  }
`;

const textContainerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h3 {
    font-size: 24px;
    font-weight: bold;
    color: #b1ffb0;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  p {
    font-size: 18px;
    color: #fff;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

const HowItWorksCard = ({ title, description, image }) => (
    <div css={cardStyles}>
        <img src={image} alt={title} css={imageStyles} />
        <div css={textContainerStyles}>
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    </div>
);

export default HowItWorksCard;
