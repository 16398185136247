/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const containerStyles = css`
  width: 100%;
  max-width: 1200px; /* Standardized width */
  margin: 0 auto; /* Center horizontally */
  padding: 40px; /* Consistent padding */
  min-height: 550px; /* Ensure sections take full viewport height */
  box-sizing: border-box; /* Include padding in the width/height calculations */
`;

const SectionContainer = ({ children, bgColor = 'transparent' }) => (
    <section
        css={css`
      background-color: ${bgColor}; /* Optional background color */
      ${containerStyles};
    `}
    >
        {children}
    </section>
);

export default SectionContainer;
