/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import CappuccinoImage from '../assets/cappuccino.svg'; // Replace with your actual image
import { useTranslation } from 'react-i18next';

const sectionStyles = css`
    background-color: var(--color-brand-medium); /* Use global brand color */
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 600px;
    margin: auto;
    position: relative;
    text-align: center;

    @media (min-width: 768px) {
        flex-direction: row;
        max-width: 1200px;
        height: 400px;
    }
`;

const leftContent = css`
    background-color: var(--color-dark-neutral-03); /* Use dark neutral color */
    color: var(--color-light-neutral-01); /* Use light neutral color */
    padding: 40px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @media (min-width: 768px) {
        width: 50%;
        align-items: flex-start;
        border-radius: 24px 0 0 24px;
    }
`;

const rightContent = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-light-neutral-01); /* Use light neutral color */
    gap: 10px;

    @media (min-width: 768px) {
        width: 50%;
    }
`;

const imageStyles = css`
    width: 120px;
    height: auto;
    margin-top: 20px;

    @media (min-width: 768px) {
        width: 200px;
    }
`;

const titleStyles = css`
    font-size: var(--font-h3-size); /* Use global typography variable */
    font-weight: var(--font-weight-medium);
    color: var(--color-brand-light); /* Use brand light color */
`;

const subtitleStyles = css`
    font-size: var(--font-h2-size); /* Use global typography variable */
    font-weight: var(--font-weight-semi-bold);
    margin-bottom: 20px;
    color: var(--color-light-neutral-01); /* Use light neutral color */
`;

const buttonContainerStyles = css`
    margin-top: 20px;

    button {
        width: 100%;
        max-width: 200px;
    }
`;

const overlayTextStyles = css`
  font-size: var(--font-h3-size); /* Use global typography variable */
  font-weight: var(--font-weight-semi-bold);
  margin-top: 10px;
  color: var(--color-brand-light); /* Use brand light color */
`;

const CTASection = () => {
    const { t } = useTranslation('cta'); // Specify the namespace for translations

    return (
        <section css={sectionStyles}>
            {/* Left Content */}
            <div css={leftContent}>
                <span css={titleStyles}>{t('getInTouch')}</span>
                <h2 css={subtitleStyles}>{t('subtitle')}</h2>
                <div css={buttonContainerStyles}>
                    <button className="cta-button">{t('ctaButton')}</button>
                </div>
            </div>

            {/* Right Content */}
            <div css={rightContent}>
                <img src={CappuccinoImage} alt={t('overlayText')} css={imageStyles} />
                <div>
                    <span>{t('overlayLabel')}</span>
                    <h2 css={overlayTextStyles}>{t('overlayText')}</h2>
                </div>
            </div>
        </section>
    );
};

export default CTASection;
